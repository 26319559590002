import { Popup as SourcePopup } from 'AdvoxComponent/Popup/Popup.component';
import { NEW_VERSION_POPUP_ID } from 'Component/NewVersionPopup/NewVersionPopup.config';

import { ESCAPE_KEY } from './Popup.config';

import './Popup.style';

/** @namespace Pwa/Component/Popup/Component */
export class Popup extends SourcePopup {
    handleKeyDown(e) {
        switch (e.keyCode) {
            case ESCAPE_KEY:
                this.hidePopUp();
                break;
            default:
                break;
        }
    }

    handleClickOutside() {
        const { clickOutside, isMobile, activeOverlay = '' } = this.props;

        if (!clickOutside) {
            return;
        }

        if (isMobile) {
            return;
        }

        this.hidePopupAndGoBack();
        if (activeOverlay === NEW_VERSION_POPUP_ID) {
            window.location.reload();
        }
    }
}

export default Popup;
